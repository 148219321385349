import { useState } from 'react';
import { includes, without } from 'lodash';
import { Stack } from '@carvertical/ui';
import { AccordionItem, type AccordionItemProps } from './AccordionItem';

type ItemPropsToInclude = 'headingLevel' | 'buttonNode' | 'contentNode' | 'id' | 'buttonClassName';

type Item = Pick<AccordionItemProps, ItemPropsToInclude>;

type AccordionProps = {
  className?: string;
  variant?: AccordionItemProps['variant'];
  items: Item[];
};

const Accordion = ({ className, items, variant }: AccordionProps) => {
  const [expandedSections, setExpandedSections] = useState<string[]>([]);

  const toggle = (id: string) => {
    setExpandedSections((currentSections) => {
      if (includes(currentSections, id)) {
        return without(currentSections, id);
      }
      return currentSections.concat(id);
    });
  };

  return (
    <Stack crossAxisAlign="stretch" gap={variant === 'plain' ? 0 : 1} className={className}>
      {items.map(({ buttonClassName, buttonNode, contentNode, headingLevel, id }) => (
        <AccordionItem
          key={id}
          id={id}
          variant={variant}
          headingLevel={headingLevel}
          expanded={expandedSections.includes(id)}
          buttonNode={buttonNode}
          contentNode={contentNode}
          onClick={() => toggle(id)}
          buttonClassName={buttonClassName}
        />
      ))}
    </Stack>
  );
};

export { Accordion };
export type { AccordionProps };
